
export const actions = {
  SET_LOADING: 'SET_LOADING'
}

const app = {
  state: {
    loading: {
      active: false,
      message: ''
    }
  },

  mutations: {
  },

  actions: {
    [actions.SET_LOADING]({ state }, data) {
      state.loading.active = data.active || false
      state.loading.message = data.message || ''
    }
  }
}

export default app
