import axios from 'axios'
import store from '../store'
import { actions as actionsUser } from '../store/modules/user'
import router from '../routers'

export const BASE_URL = `${process.env.VUE_APP_API_URL}/api`

axios.defaults.baseURL = BASE_URL
axios.defaults.timeout = 60 * 1000 // 60 seconds

const api = axios.create()
const apiAuth = axios.create()

api.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

apiAuth.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(response => {
  return response
}, handleError)

apiAuth.interceptors.response.use(response => {
  if (response.status === 401 || response.status === 403 || response.status === 410) {
    store.dispatch(actionsUser.LOGOUT)
    router.push({ name: 'login' })
  }
  return response
}, handleError)

const handleError = error => {
  if (error.response.status === 401 || error.response.status === 403 || error.response.status === 410) {
    store.dispatch(actionsUser.LOGOUT)
    router.push({ name: 'login' })
  }
}

export const setAccessToken = token => {
  apiAuth.defaults.headers.common.Authorization = `JWT ${token}`
}

export const loginApi = async ({ email, password }) => {
  try {
    const res = await api.post('/login/', { email, password })
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const getUserMeApi = async () => {
  try {
    const res = await apiAuth.get('/user/')
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const getTokenPreRegisterApi = async (params) => {
  try {
    const res = await api.post('/user_token/', params)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const postResetPasswordApi = async (params) => {
  try {
    const res = await api.post('/reset_password', params)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const putResetPasswordApi = async (params) => {
  try {
    const res = await api.put(`/reset_password?token=${params.token}`, params)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const getResetPasswordApi = async (params) => {
  try {
    const res = await api.get(`/reset_password?token=${params.token}`)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const getRegisterInfoApi = async (params) => {
  try {
    const res = await api.get(`/register/?token=${params.token}`)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const validateUserInfoApi = async (params) => {
  try {
    const res = await api.post('/user_validate/', params)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const registerApi = async (params) => {
  try {
    const res = await api.post('/register/', params)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const getUserDetailApi = async (id) => {
  try {
    const res = await apiAuth.get(`/user_detail/${id}`)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const deleteUserApi = async (id) => {
  try {
    const res = await apiAuth.delete(`/delete_user/${id}`)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const getCategoriesApi = async () => {
  try {
    const res = await apiAuth.get(`/categories/`)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const getCharactersApi = async () => {
  try {
    const res = await apiAuth.get(`/characters/`)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const createProjectApi = async (params) => {
  try {
    const res = await apiAuth.post('/create_project/', params)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const exportCsvUsersApi = async (params) => {
  try {
    const res = await apiAuth.get('/users_csv/', { params })
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const exportZipResultApi = async (params) => {
  try {
    const res = await apiAuth.post('/export_zip_result/', params, { responseType: 'blob' })
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}

export const validateWordApi = async (params) => {
  try {
    const res = await apiAuth.post('/validate_word/', params)
    return res
  } catch (e) {
    handleError(e)
    return e.response
  }
}
