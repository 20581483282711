import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import app from './modules/app'
import project from './modules/project'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { user, app, project },
  getters
})

if (module.hot) {
  module.hot.accept(
    ['./modules/user', './modules/app', './modules/project'],
    () => {
      const user = require('./modules/user').default
      const app = require('./modules/app').default
      const project = require('./modules/project').default

      store.hotUpdate({
        modules: { user, app, project }
      })
    }
  )
}

export default store
