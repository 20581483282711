import store from '@/store'
import storage from './services/storage'
import { persistKeys } from './common/constant'
import { getUserMeApi, setAccessToken } from './services/api'

export async function getUserMe() {
  const res = await getUserMeApi()
  if (res.status === 200) {
    store.state.user.userInfo = res.data
  }
}

export function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]

  if (!subsequentMiddleware) {
    return context.next
  }

  return (...parameters) => {
    context.next(...parameters)

    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

export async function guest({ next, router }) {
  if (!store.state.user.initialized) {
    const token = storage.get(persistKeys.USER_TOKEN)
    if (token) {
      setAccessToken(token)
      await getUserMe()
    }
    store.state.user.initialized = true
  }
  if (store.getters.userInfo.id && store.getters.userInfo.is_superuser) {
    return router.push({ name: 'ListUser' })
  }
  if (store.getters.userInfo.id && !store.getters.userInfo.is_superuser) {
    return router.push({ name: 'SelectMenu' })
  }
  return next()
}

export async function auth({ next, router }) {
  if (!store.state.user.initialized) {
    const token = storage.get(persistKeys.USER_TOKEN)
    if (token) {
      setAccessToken(token)
      await getUserMe()
    }
    store.state.user.initialized = true
  }
  if (!store.getters.userInfo.id) {
    return router.push({ name: 'login' })
  }

  return next()
}

export async function admin({ next, router }) {
  if (!store.getters.userInfo.is_superuser) {
    return router.push({ name: 'SelectMenu' })
  }

  return next()
}

export async function client({ next, router }) {
  if (store.getters.userInfo.is_superuser) {
    return router.push({ name: 'ListUser' })
  }

  return next()
}
