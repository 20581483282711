import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  auth,
  nextFactory,
  admin,
  client,
  guest
} from './middleware'
const Login = () => import('./views/auth/Login')
const PageNotFound = () => import('./views/404')
const LayoutCommon = () => import('./components/LayoutCommon')
const LayoutAdmin = () => import('./components/LayoutAdmin')
const PreRegister = () => import('./views/auth/PreRegister')
const MailSend = () => import('./views/auth/MailSend')
const MailSendResetPass = () => import('./views/auth/MailSendResetPass')
const PreResetPassword = () => import('./views/auth/PreResetPassword')
const ResetPassword = () => import('./views/auth/ResetPassword')
const ResetPasswordSuccess = () => import('./views/auth/ResetPasswordSuccess')
const Register = () => import('./views/auth/Register')
const RegisterConfirm = () => import('./views/auth/RegisterConfirm')
const RegisterSuccess = () => import('./views/auth/RegisterSuccess')
const ListUser = () => import('./views/admin/ListUser')
const SelectMenu = () => import('./views/project/SelectMenu')
const CustomInput = () => import('./views/project/CustomInput')
const RecommendInput = () => import('./views/project/RecommendInput')
const Result = () => import('./views/project/Result')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      name: '404',
      component: PageNotFound
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'ログイン｜PLUG キャラクター売上予測AI',
        middleware: [guest]
      }
    },
    {
      path: '/',
      redirect: { name: 'login' },
      component: LayoutCommon,
      children: [
        {
          path: '/register/pre_register',
          name: 'PreRegister',
          component: PreRegister,
          meta: {
            title: '本登録URL送信先アドレスの入力｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/register/mail_send',
          name: 'MailSend',
          component: MailSend,
          meta: {
            title: '送信完了｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/register/mail_send_reset_password',
          name: 'MailSendResetPass',
          component: MailSendResetPass,
          meta: {
            title: '送信完了｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/register/:token',
          name: 'Register',
          component: Register,
          meta: {
            title: '新規ユーザー登録｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/register_confirm',
          name: 'RegisterConfirm',
          component: RegisterConfirm,
          meta: {
            title: '登録内容の確認｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/register_success',
          name: 'RegisterSuccess',
          component: RegisterSuccess,
          meta: {
            title: '登録完了｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/pre_reset_password',
          name: 'PreResetPassword',
          component: PreResetPassword,
          meta: {
            title: 'パスワード再発行｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/reset_password_success',
          name: 'ResetPasswordSuccess',
          component: ResetPasswordSuccess,
          meta: {
            title: 'パスワード変更完了｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        },
        {
          path: '/register/password_new/:token',
          name: 'ResetPassword',
          component: ResetPassword,
          meta: {
            title: 'パスワード再設定｜PLUG キャラクター売上予測AI',
            middleware: [guest]
          }
        }
      ]
    },
    {
      path: '/admin',
      component: LayoutAdmin,
      children: [
        {
          path: '/',
          name: 'ListUser',
          component: ListUser,
          meta: {
            title: 'PLUGダッシュボード｜PLUG キャラクター売上予測AI',
            middleware: [auth, admin]
          }
        }
      ]
    },
    {
      path: '/project/',
      component: LayoutCommon,
      redirect: { name: 'SelectMenu' },
      children: [
        {
          path: 'select-menu',
          name: 'SelectMenu',
          component: SelectMenu,
          meta: {
            title: '操作メニュー選択｜PLUG キャラクター売上予測AI',
            middleware: [auth, client]
          }
        },
        {
          path: 'custom-input',
          name: 'CustomInput',
          component: CustomInput,
          meta: {
            title: '入力画面｜PLUG キャラクター売上予測AI',
            middleware: [auth, client]
          }
        },
        {
          path: 'recommend-input',
          name: 'RecommendInput',
          component: RecommendInput,
          meta: {
            title: '入力画面｜PLUG キャラクター売上予測AI',
            middleware: [auth, client]
          }
        },
        {
          path: 'result',
          name: 'Result',
          component: Result,
          props: true,
          meta: {
            title: '結果表示｜PLUG キャラクター売上予測AI',
            middleware: [auth, client]
          }
        }
      ]
    }
  ]
})

const DEFAULT_TITLE = 'PLUG キャラクター売上予測AI'

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
