import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  'en': {},
  'jp': {
    loginFail: 'ログイン内容が正しくないか無効です。'
  }
}

const i18n = new VueI18n({
  locale: 'jp', // set locale
  fallbackLocale: 'jp', // set fallback locale
  messages // set locale messages
})

export default i18n
