<template>
  <div>
    <div id="loader-bg">
      <div class="loading">
        <div id="loader">
          <img src="@/assets/img/loader.gif">
        </div>
        <div v-if="message" id="message-text">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {

    }
  },
  computed: {
    message() {
      return this.$store.getters.loading.message
    }
  }
}
</script>

<style scoped>
#loader-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 9999 !important;
  opacity: 0.8;
  display: block;
}
#loader {
  width: 100px;
  height: 100px;
  text-align: center;
  color: #fff;
  z-index: 2;
  margin: auto;
  display: block;
}
#message-text {
  color: #2981d2;
  text-align: center;
  font-size: 1.1em;
  margin: auto;
}
.loading {
  position: fixed;
  top: calc(35% + 50px);
  left: calc(50% + -230px);
  width: 480px;
}
</style>
