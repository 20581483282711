export const persistKeys = {
  USER_TOKEN: 'token'
}
export const MENU = {
  MENU_1: 1,
  MENU_2: 2
}
export const typeVariable = {
  CATEGORY: 1,
  CHARACTER: 2
}
export const maxRows = 5
export const titleResultCustom = '数表とグラフ (キャラクター名×カテゴリー名)'

