import storage from '../../services/storage'
import { persistKeys } from '../../common/constant'
import { setAccessToken } from '../../services/api'

export const actions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_USER_INFO: 'SET_USER_INFO',
  CLEAR_USER_INFO: 'CLEAR_USER_INFO',
  GET_USER_INFO: 'GET_USER_INFO',
  SET_USER_REGISTER: 'SET_USER_REGISTER'
}

const user = {
  state: {
    initialized: false,
    userInfo: {},
    userRegister: {}
  },

  mutations: {
  },

  actions: {
    async [actions.LOGIN]({ commit }, data) {
      await setAccessToken(data.token)
      storage.set(persistKeys.USER_TOKEN, data.token)
    },
    [actions.LOGOUT]({ state }) {
      state.userInfo = {}
      storage.remove(persistKeys.USER_TOKEN)
    },
    [actions.SET_USER_REGISTER]({ state }, data) {
      state.userRegister = data
    },
    [actions.SET_USER_INFO]({ state }, data) {
      state.userInfo = data
    }
  }
}

export default user
