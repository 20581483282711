<template>
  <div id="app">
    <Loading v-if="isLoading" />
    <router-view />
  </div>
</template>

<script>
import Loading from './components/Loading'
export default {
  name: 'App',
  components: {
    Loading
  },
  computed: {
    isLoading() {
      return this.$store.getters.loading.active
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'PLUG キャラクター売上予測AI'
      }
    }
  }

}
</script>

<style>
#app {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.4rem;
  color: #000;
  font-weight: 500;
  line-height: 1;
  font-feature-settings: "palt" 1;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

@import '../public/asset/css/common/bootstrap.css';
@import "../public/asset/css/common/bootstrap-theme.css";
@import "../public/asset/css/common/formparts.css";
@import "../public/asset/css/style.css";
</style>
