const getters = {
  userRegister: state => state.user.userRegister,
  userInfo: state => state.user.userInfo,
  loading: state => state.app.loading,
  menu: state => state.project.menu,
  categories: state => state.project.categories,
  characters: state => state.project.characters,
  categoriesForSubmit: state => state.project.categoriesForSubmit,
  charactersForSubmit: state => state.project.charactersForSubmit,
  categoriesCustomInput: state => state.project.categoriesCustomInput,
  charactersCustomInput: state => state.project.charactersCustomInput,
  messageErrorCustomInput: state => state.project.messageErrorCustomInput,
  categoryRecommendInput: state => state.project.categoryRecommendInput,
  characterRecommendInput: state => state.project.characterRecommendInput,
  messageErrorCategoryRecommendInput: state => state.project.messageErrorCategoryRecommendInput,
  messageErrorCharacterRecommendInput: state => state.project.messageErrorCharacterRecommendInput,
  categoriesCustomInputValid: state => state.project.categoriesCustomInputValid,
  charactersCustomInputValid: state => state.project.charactersCustomInputValid,
  typeRecommendInput: state => state.project.typeRecommendInput,
  result: state => state.project.result
}

export default getters
