import Vue from 'vue'
import App from './App.vue'
import 'select2/dist/js/select2.full'
import { persistKeys } from './common/constant'
import router from './routers'
import i18n from './services/i18n'
import store from './store'
import Toasted from 'vue-toasted'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(Toasted, {
  position: 'top-right',
  duration: 5000,
  preventDuplicates: true
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

// listen action access token change
window.addEventListener('storage', e => {
  if (e.key === persistKeys.USER_TOKEN && e.oldValue !== e.newValue) {
    location.reload()
  }
})
