import { typeVariable } from '../../common/constant'

export const actions = {
  SET_MENU: 'SET_MENU',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_CHARACTERS: 'SET_CHARACTERS',
  SET_CATEGORIES_FOR_SUBMIT: 'SET_CATEGORIES_FOR_SUBMIT',
  SET_CHARACTERS_FOR_SUBMIT: 'SET_CHARACTERS_FOR_SUBMIT',
  SET_CATEGORIES_CUSTOM_INPUT: 'SET_CATEGORIES_CUSTOM_INPUT',
  SET_CHARACTERS_CUSTOM_INPUT: 'SET_CHARACTERS_CUSTOM_INPUT',
  SET_CATEGORY_RECOMMEND_INPUT: 'SET_CATEGORY_RECOMMEND_INPUT',
  SET_CHARACTER_RECOMMEND_INPUT: 'SET_CHARACTER_RECOMMEND_INPUT',
  SET_MESSAGE_ERROR_CUSTOM_INPUT: 'SET_MESSAGE_ERROR_CUSTOM_INPUT',
  SET_MESSAGE_ERROR_CATEGORY_RECOMMEND_INPUT: 'SET_MESSAGE_ERROR_CATEGORY_RECOMMEND_INPUT',
  SET_MESSAGE_ERROR_CHARACTER_RECOMMEND_INPUT: 'SET_MESSAGE_ERROR_CHARACTER_RECOMMEND_INPUT',
  SET_CATEGORIES_CUSTOM_INPUT_VALID: 'SET_CATEGORIES_CUSTOM_INPUT_VALID',
  SET_CHARACTERS_CUSTOM_INPUT_VALID: 'SET_CHARACTERS_CUSTOM_INPUT_VALID',
  SET_TYPE_RECOMMEND_INPUT: 'SET_TYPE_RECOMMEND_INPUT',
  SET_RESULT: 'SET_RESULT',
  RESET_DATA_CUSTOM_INPUT: 'RESET_DATA_CUSTOM_INPUT',
  RESET_DATA_RECOMMEND_INPUT: 'RESET_DATA_RECOMMEND_INPUT'
}

const project = {
  state: {
    menu: 0,
    categories: [],
    characters: [],
    categoriesForSubmit: [],
    charactersForSubmit: [],
    categoriesCustomInput: [],
    charactersCustomInput: [],
    messageErrorCustomInput: '',
    categoriesCustomInputValid: [],
    charactersCustomInputValid: [],
    categoryRecommendInput: {},
    characterRecommendInput: {},
    messageErrorCategoryRecommendInput: '',
    messageErrorCharacterRecommendInput: '',
    typeRecommendInput: typeVariable.CHARACTER,
    result: {}
  },

  mutations: {
  },

  actions: {
    [actions.SET_MENU]({ state }, data) {
      state.menu = data || 0
    },
    [actions.SET_CATEGORIES]({ state }, data) {
      state.categories = data || []
    },
    [actions.SET_CHARACTERS]({ state }, data) {
      state.characters = data || []
    },
    [actions.SET_CATEGORIES_FOR_SUBMIT]({ state }, data) {
      state.categoriesForSubmit = data || []
    },
    [actions.SET_CHARACTERS_FOR_SUBMIT]({ state }, data) {
      state.charactersForSubmit = data || []
    },
    [actions.SET_CATEGORIES_CUSTOM_INPUT]({ state }, data) {
      state.categoriesCustomInput = data || []
    },
    [actions.SET_CHARACTERS_CUSTOM_INPUT]({ state }, data) {
      state.charactersCustomInput = data || []
    },
    [actions.SET_MESSAGE_ERROR_CUSTOM_INPUT]({ state }, data) {
      state.messageErrorCustomInput = data || ''
    },
    [actions.SET_CATEGORY_RECOMMEND_INPUT]({ state }, data) {
      state.categoryRecommendInput = data || {}
    },
    [actions.SET_CHARACTER_RECOMMEND_INPUT]({ state }, data) {
      state.characterRecommendInput = data || {}
    },
    [actions.SET_MESSAGE_ERROR_CATEGORY_RECOMMEND_INPUT]({ state }, data) {
      state.messageErrorCategoryRecommendInput = data || ''
    },
    [actions.SET_MESSAGE_ERROR_CHARACTER_RECOMMEND_INPUT]({ state }, data) {
      state.messageErrorCharacterRecommendInput = data || ''
    },
    [actions.SET_CATEGORIES_CUSTOM_INPUT_VALID]({ state }, data) {
      state.categoriesCustomInputValid = data || []
      state.categoriesCustomInput = data || []
      const array = []
      data.forEach(item => {
        if (item.value !== '' && !array.includes(item.value)) { array.push(item.value) }
      })
      state.categoriesForSubmit = array
    },
    [actions.SET_CHARACTERS_CUSTOM_INPUT_VALID]({ state }, data) {
      state.charactersCustomInputValid = data || []
      state.charactersCustomInput = data || []
      const array = []
      data.forEach(item => {
        if (item.value !== '' && !array.includes(item.value)) { array.push(item.value) }
      })
      state.charactersForSubmit = array
    },
    [actions.SET_TYPE_RECOMMEND_INPUT]({ state }, data) {
      state.typeRecommendInput = data || typeVariable.CHARACTER
    },
    [actions.SET_RESULT]({ state }, data) {
      state.result = data || {}
    },
    [actions.RESET_DATA_CUSTOM_INPUT]({ state }) {
      state.categoriesCustomInput = []
      state.charactersCustomInput = []
      state.messageErrorCustomInput = ''
      state.categoriesCustomInputValid = []
      state.charactersCustomInputValid = []
    },
    [actions.RESET_DATA_RECOMMEND_INPUT]({ state }) {
      state.categoryRecommendInput = []
      state.characterRecommendInput = []
      state.messageErrorCategoryRecommendInput = ''
      state.messageErrorCharacterRecommendInput = ''
      state.typeRecommendInput = typeVariable.CHARACTER
    }
  }
}

export default project
